import React from 'react'

const Footer = () => {
  return (
    <div style={{height:"5vh",background:"black",textAlign:"center",color:"white",padding:'8px'}}>
       Disclaimer:This site is for information purpose only and should not be treated as the official information. 2023
        </div>
  )
}

export default Footer